import './Footer.css'
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';


export default function Footer() {
    const { t } = useTranslation(); 
    return (
        <footer>
            <div className="footer" id="footer">
                <div className="row">
                    <Link><i className="fa fa-facebook"></i></Link>
                    <Link><i className="fa fa-instagram"></i></Link>
                    <Link><i className="fa fa-youtube"></i></Link>
                    <Link><i className="fa fa-twitter"></i></Link>
                </div>

                <div className="row">
                    <ul>
                        <li>{t('footer.OurService')}</li>
                        <li>{t('footer.privacyPol')}</li>
                        <li>{t('footer.terms')}</li>
                    </ul>
                </div>

                <div className="row">
                    {t('footer.copyrights')}
                </div>
            </div>
        </footer>
    )
}