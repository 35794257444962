import React, { useEffect } from 'react';
import './App.css';
import { ChakraProvider, theme } from "@chakra-ui/react";
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FindNearby from './pages/FindNearby';
import PlanRoute from './pages/PlanRoute';
import ErrorPage from './pages/ErrorPage';
import Header from "./components/Navbar/Header"
import Footer from "./components/Footer/Footer"
import './i18n';


function App() {
  useEffect(() => {
    // Définition des options de Doorbell
    window.doorbellOptions = {
      id: "14196",
      appKey: "bYSi3M6PcdB7NF4cEA4NRo39mY7wBOdCDdCbKL5XOmlBby4GgTm7NBsDgRZ798gP",
      windowLoaded: true
    };

    // Chargement du script de Doorbell
    const doorbellScript = document.createElement('script');
    doorbellScript.id = 'doorbellScript';
    doorbellScript.type = 'text/javascript';
    doorbellScript.async = true;
    doorbellScript.crossOrigin = 'anonymous';
    doorbellScript.src = 'https://embed.doorbell.io/button/' + window.doorbellOptions.id + '?t=' + (new Date().getTime());

    // Ajout du script au document
    document.body.appendChild(doorbellScript);

    // Nettoyage du script lors du démontage du composant
    return () => {
      document.body.removeChild(doorbellScript);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/find-nearby" element={<FindNearby />} />
          <Route path="/plan-your-route" element={<PlanRoute />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
