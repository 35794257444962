
import { Box,  HStack,  Spacer, Text, Image, Divider, AbsoluteCenter, SimpleGrid, Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'
import { CheckIcon, CheckCircleIcon, StarIcon } from '@chakra-ui/icons'
import { FaLocationDot, FaMapLocationDot, FaArrowTrendDown  } from "react-icons/fa6";
import { FaLocationArrow, FaEuroSign  } from "react-icons/fa";
import React from 'react';
import { useTranslation } from 'react-i18next';



export default function Home() {
    const { t } = useTranslation(); 
    return (
        <>

            <Box display='flex' flexDirection={['column', 'column', 'row']} alignItems={'center'} height='100vh' mx='10%' mb={[100, 100, 0]} mt={['50px', '60px', 0]} >
                <Box >

                    <Text as='b' fontSize={['25px', '30px', '40px']}>{t('Home.title')}</Text>


                    <HStack pt={5} alignItems={'start'}>
                        <CheckIcon color="orange" mr={[2, 2, 5]} />
                        <Text textAlign={'left'} fontSize={['lg', 'lg', 'xl']}>{t('Home.headcheck1')}</Text>
                    </HStack>

                    <HStack alignItems={'start'}>
                        <CheckIcon color="orange" mr={[2, 2, 5]} />
                        <Text textAlign={'left'} fontSize={['lg', 'lg', 'xl']}>{t('Home.headcheck2')}</Text>
                    </HStack>

                    <HStack pl={5} pt={6}>
                        <CheckCircleIcon color="green" mr={[2, 2, 5]} />
                        <Text textAlign={'left'} fontSize={['lg', 'lg', 'xl']}>{t('Home.greencheck1')}</Text>
                    </HStack>

                    

                </Box>
                <Spacer />
                <Box>
                    <Image src='city_driver.gif' alt='city_driver' width={900}/>
                </Box>

            </Box>

            <Box position='relative' px={[50, 50, 100]} mb={['70px', '70px', '120px']}>
                <Divider borderWidth={2} />
                <AbsoluteCenter as='b' bg='white' px={['5px', '8px', '10px']} fontSize={[18, 20, 25]}>
                    {t('Home.appFeatures')}
                </AbsoluteCenter>
            </Box>


            <Box mb={40} ml={['15px', '30px', '50px']} mr={['15px', '30px', '50px']}>

                <SimpleGrid spacing={10} minChildWidth={['330px', '330px', '400px']}>
                    <Card>
                        <CardHeader>
                            <HStack>
                                <FaEuroSign color="orange"/>
                                <Heading size='md'>{t('Home.feat1.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        
                        <CardBody>
                            <Text>{t('Home.feat1.Desc')}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <HStack>
                                <FaLocationDot color="orange"/>
                                <Heading size='md'>{t('Home.feat2.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text>{t('Home.feat2.Desc')}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <HStack>
                                <FaLocationArrow color="orange"/>
                                <Heading size='md'>{t('Home.feat3.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text>{t('Home.feat3.Desc')}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <HStack>
                                <FaMapLocationDot color="orange"/>
                                <Heading size='md'>{t('Home.feat4.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text>{t('Home.feat4.Desc')}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <HStack>   
                                <FaArrowTrendDown color="orange"/>
                                <Heading size='md'>{t('Home.feat5.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text>{t('Home.feat5.Desc')}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <HStack>
                                <StarIcon color="orange" />
                                <Heading size='md'>{t('Home.feat6.Title')}</Heading>
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text>{t('Home.feat6.Desc')}</Text>
                        </CardBody>
                    </Card>
                    
                </SimpleGrid>
            </Box>

        </>
    )
}
