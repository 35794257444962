import StationCards from '../components/StationCards/StationCards'
import './PlanRoute.css'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  SkeletonText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  AbsoluteCenter,
  Divider,
  Text,
  Spinner
} from '@chakra-ui/react'
import { FaTimes } from 'react-icons/fa'
import { BsFillGeoAltFill } from 'react-icons/bs'
import {
  useJsApiLoader,
  GoogleMap,
  Autocomplete,
  DirectionsRenderer,
  MarkerF,
  InfoWindowF
} from '@react-google-maps/api'
import { useRef, useState, useEffect } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { SimpleGrid } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';



const libraries = ['places']

const searchOptions = {
  componentRestrictions: { country: 'be' }, // 'be' est le code pays pour la Belgique
};

const mapcenter = { lat: 50.847761, lng: 4.351412 }


export default function PlanRoute() {
  const { t } = useTranslation(); 

  const [center, setCenter] = useState(mapcenter);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAUDSlDzK7DIOv4L54aw1PBhiiTV79wru4",
    libraries: libraries,
  })

  const [error, setError] = useState(null);

  const [map, setMap] = useState(/** @type google.maps.Map */(null))

  const [allStations, setAllStations] = useState(null)
  const [closetStation, setClosetStation] = useState(null)
  const [minDistance, setMinDistance] = useState(null)
  const [minPrice, setMinPrice] = useState(null)
  const [minCost, setMinCost] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [fuelType, setFuelType] = useState(null)
  const [selectedStation, setSelectedStation] = useState("");
  const [filteredStations, setFilteredStations] = useState([]);
  const [selectedSort, setSelectedSort] = useState("default")
  const [directionsResponse, setDirectionsResponse] = useState(null)
  // const [overviewPolyline, setOverviewPolyline] = useState("hhhh")
  const [distance, setDistance] = useState(null)

  /**@type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  const destinationRef = useRef()
  const autonomyRef = useRef()
  const capacityRef = useRef()
  const consumptionRef = useRef()


  //const myIcon = 'icon_marker.png'
  const spriteUrl = 'markers.png'

  useEffect(() => {
    // Initialisation des stations filtrées avec toutes les stations
    setFilteredStations(allStations);

  }, [allStations]);


  if (!isLoaded) {
    return <SkeletonText />
  }

  const handleMarkerClick = (station) => {
    // Update the state with the selected station information
    setSelectedStation(station);
  };

  const handleInfoWindowClose = () => {
    setSelectedStation(null);
  };

  async function calculateRoute() {

    console.assert(originRef.current.value !== '', "Origin should not be empty");
    console.assert(destinationRef.current.value !== '', "Destination should not be empty");


    if (originRef.current.value === '' || destinationRef.current.value === '') {
      return
    }
    try {

      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const results = await directionsService.route({
        origin: originRef.current.value,
        destination: destinationRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      console.assert(results !== null, "Direction results should not be null");
      console.assert(results.routes.length > 0, "Direction results should have at least one route");
      console.assert(results.routes[0].legs.length > 0, "Route should have at least one leg");


      // setOverviewPolyline(results.routes[0].overview_polyline)
      setDirectionsResponse(results)
      setDistance(results.routes[0].legs[0].distance.value)
      setError(null)
    } catch (error) {
      console.log("error function calculateRoute");
    }
  }

  function clearOrigin() {
    originRef.current.value = '';
    setError(null)
  }

  function clearDestination() {
    destinationRef.current.value = '';
    setError(null)
  }

  const handleSortChange = (event) => {
    console.assert(allStations !== null, "All stations should not be null");

    const selectedSortOption = event.target.value;
    setSelectedSort(selectedSortOption)

    if (allStations === null) {
      // Handle the case when allStations is null
      return;
    }

    if (selectedSortOption === 'E5' || selectedSortOption === 'E10' || selectedSortOption === 'B7') {
      // Utiliser la fonction de tri avec le type de carburant sélectionné
      const sorted = allStations.slice().sort((a, b) => {
        const priceA = a.fuel_prices[selectedSortOption] === '-' ? Infinity : a.fuel_prices[selectedSortOption];
        const priceB = b.fuel_prices[selectedSortOption] === '-' ? Infinity : b.fuel_prices[selectedSortOption];
        return priceA - priceB;
      });
      setFilteredStations(sorted);
    } else if (selectedSortOption === 'default') {
      setFilteredStations(allStations);
    } else if (selectedSortOption === 'distance') {
      const sorted = allStations.slice().sort((a, b) => a.routeInfo.distance - b.routeInfo.distance);
      setFilteredStations(sorted);
    } else if (selectedSortOption === 'duration') {
      const sorted = allStations.slice().sort((a, b) => a.routeInfo.duration - b.routeInfo.duration);
      setFilteredStations(sorted);
    }
  };

  const handleFormSubmit = async () => {
    // Validation for Origin
    console.assert(originRef.current.value !== '', "Origin is required");

    const origin = originRef.current.value;
    if (!origin) {
      setError("Origin is required");
      return;
    }

    // Validation for Destination
    console.assert(destinationRef.current.value !== '', "Destination is required");

    const dest = destinationRef.current.value;
    if (!dest) {
      setError("Destination is required");
      return;
    }

    // Validation for Fuel Type
    console.assert(fuelType === 'E5' || fuelType === 'E10' || fuelType === 'B7', "Fuel type should be either 'Super 98 (E5)', 'Super 95 (E10)', or 'Diesel (B7)'");
    if (!fuelType) {
      setError("Fuel type is required");
      return;
    }

    // Validation for Autonomy
    const autonomyValue = parseFloat(autonomyRef.current.value);
    console.assert(!isNaN(autonomyValue) && autonomyValue >= 0 && autonomyValue <= 700, "Autonomy should be a number between 0 and 700");
    if (isNaN(autonomyValue) || autonomyValue < 0 || autonomyValue > 700) {
      setError("Autonomy should be a number between 0 and 700");
      return;
    }
    const autonomy = autonomyValue.toString();

    // Validation for Capacity
    const capacity = capacityRef.current.value;


    // Validation for Consumption
    const consumption = consumptionRef.current.value;



    // If all validations pass, reset the error state
    setError(null);
    setIsSubmitting(true);

    let originlatLng, destlatLng;

    try {
      try {
        const originRes = await geocodeByAddress(origin);
        console.assert(originRes !== null, "Origin geocode response should not be null");
        originlatLng = await getLatLng(originRes[0]);
        console.assert(originlatLng !== null, "Origin LatLng should not be null");

        const destRes = await geocodeByAddress(dest);
        console.assert(destRes !== null, "Destination geocode response should not be null");
        destlatLng = await getLatLng(destRes[0]);
        console.assert(destlatLng !== null, "Destination LatLng should not be null");
      } catch (invalidAddresses) {
        setError("Invalid address");
        return;
      }

      setError(null);
      setCenter(originlatLng);

      if (map) {
        map.panTo(originlatLng);
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/plan-your-route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          origin: originlatLng,
          destination: destlatLng,
          autonomy: autonomy,
          capacity: capacity,
          consumption: consumption,
          fuelType: fuelType,
        }),
      });

      if (!response.ok) {
        throw new Error('communicating with the server');
      }

      const data = await response.json();
      console.assert(data !== null, "Server response should not be null");

      setAllStations(data.stations_data);
      setClosetStation(data.closetStation);
      setMinDistance(data.minDistance);
      setMinPrice(data.minPrice);
      setMinCost(data.minCost);

      calculateRoute();

      setIsSubmitting(false);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error communicating with the server:", error);
    }
  };

  const handleLocaButton = () => {
    console.assert(navigator.geolocation !== undefined, "Geolocation should be supported by the browser");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.assert(position !== null && position.coords !== null, "Position data should not be null");

          const { latitude, longitude } = position.coords;
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

          fetch(url)
            .then(res => {
              console.assert(res !== null && res.ok, "Response should not be null and should be successful");
              return res.json();
            })
            .then(data => {
              console.assert(data !== null && data.address !== null, "Data should not be null and should contain address information");

              const adrObj = data.address;
              console.assert(adrObj.road !== undefined && adrObj.city !== undefined, "Address object should contain road and city information");

              const adr = `${adrObj.road}, ${adrObj.city}`;
              originRef.current.value = adr;
            })
            .catch(error => {
              console.error('Error processing location data:', error);
            });
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleGPSClick = (lat, lng) => {
    // Construct the Google Maps URL with latitude and longitude
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

    // Open a new window with the Google Maps URL
    window.open(googleMapsUrl, '_blank');
  };


  return (
    <>
      <Flex
        position='relative'
        flexDirection='column'
        alignItems='center'
        mb={10}

      >
        <Box
          p={4}
          m={4}
          mr={4}
          ml={4}
          borderRadius='lg'
          bgColor='#faf6ea '
          width={['80%', '70%', '50%']}
        >

          <Stack spacing={3}>
            <InputGroup bgColor='white'>
              <InputLeftElement>
                <IconButton
                  aria-label='localisation'
                  icon={<BsFillGeoAltFill />}
                  onClick={handleLocaButton}
                  size='sm'
                />
              </InputLeftElement>

              <Autocomplete className='autocomplete' options={searchOptions}>
                <Input pl={12} type='text' ref={originRef} placeholder={t('planRoute.origin')}/>
              </Autocomplete>

              <InputRightElement>
                <IconButton
                  aria-label='clear'
                  icon={<FaTimes />}
                  size='sm'
                  onClick={clearOrigin}
                />
              </InputRightElement>

            </InputGroup>

            {error && error === "Origin is required" && (
              <Alert mt={2} status='error'>
                <AlertIcon />
                <AlertTitle>{t('planRoute.originRequired')}</AlertTitle>
              </Alert>
            )}

            <InputGroup bgColor='white'>
              <Autocomplete className='autocomplete' options={searchOptions}>
                <Input type='text' ref={destinationRef} placeholder={t('planRoute.destination')} required />
              </Autocomplete>
              <InputRightElement>
                <IconButton
                  aria-label='clear'
                  icon={<FaTimes />}
                  onClick={clearDestination}
                  size='sm'
                />
              </InputRightElement>
            </InputGroup>

            {error && error === "Destination is required" && (
              <Alert mt={2} status='error'>
                <AlertIcon />
                <AlertTitle>{t('planRoute.destinationRequired')}</AlertTitle>
              </Alert>
            )}

            <Select
              bgColor='white'
              placeholder={t('planRoute.selectYourFuel')}
              onChange={(e) => setFuelType(e.target.value)}>
              <option value='E10'>{t('planRoute.super95')}</option>
              <option value='E5'>{t('planRoute.super98')}</option>
              <option value='B7'>{t('planRoute.Diesel')}</option>
            </Select>

            {error && error === "Fuel type is required" && (
              <Alert mt={2} status='error'>
                <AlertIcon />
                <AlertTitle>{t('planRoute.fuelTypeRequired')}</AlertTitle>
              </Alert>
            )}

            <NumberInput step={50} min={0} max={700} bgColor='white'>
              <NumberInputField ref={autonomyRef} placeholder={t('planRoute.autonomyRemaining')} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {error && error === "Autonomy is required" && (
              <Alert mt={2} status='error'>
                <AlertIcon />
                <AlertTitle>{t('planRoute.autonomyRequired')}</AlertTitle>
              </Alert>
            )}

            <NumberInput step={5} min={0} max={200} bgColor='white'>
              <NumberInputField ref={capacityRef} placeholder={t('planRoute.vehiculeCapacity')} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <NumberInput step={2} min={0} max={20} bgColor='white'>
              <NumberInputField ref={consumptionRef} placeholder={t('planRoute.vehicleConsumption')}/>
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >

              {isSubmitting ? (
                <Spinner justifyContent='center' />
              ) : (

                (!isSubmitted && <Button type='submit' colorScheme='blue' onClick={handleFormSubmit}>{t('planRoute.search')}</Button>)

              )}

              {(isSubmitted && <Button type='submit' colorScheme='orange'><a href="/plan-your-route">{t('planRoute.newItinerary')}</a></Button>)}

            </Box>

          </Stack>

          {error && error === "Invalid address" && (<Alert mt={2} status='error'>
            <AlertIcon />
            <AlertTitle>{t('planRoute.invaldInput')}</AlertTitle>
            <AlertDescription>{t('planRoute.fillValidAddress')}</AlertDescription>
          </Alert>
          )}

        </Box>

        {(allStations === undefined || allStations?.length === 0) && isSubmitted === true &&
          (
            <Flex m={5}>
              <Alert status='warning'>
                <AlertIcon />
                {t('planRoute.noFuelStation')}
              </Alert>
            </Flex>
          )
        }

        <Box h='600px' w='80%'>
          <GoogleMap
            center={center}
            zoom={13}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={map => setMap(map)}
          >

            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />)
            }

            {allStations?.length > 0 && allStations.map((station) => (

              <MarkerF
                key={station.id}
                position={{ lat: parseFloat(station.lat), lng: parseFloat(station.lng) }}
                onClick={() => handleMarkerClick(station)}
                icon={{
                  url: spriteUrl,
                  size: new window.google.maps.Size(21.5, 33.5), // Full size of your sprite image (width, height)
                  origin: new window.google.maps.Point(0, 0), // Starting point of the first icon
                  scaledSize: new window.google.maps.Size(64.5, 33.5) // Displayed size of one icon
              }}
              >
              </MarkerF>
            ))}

            {minCost?.length > 0 && minCost.map((station) => (

              <MarkerF
                key={station.id}
                position={{ lat: parseFloat(station.lat), lng: parseFloat(station.lng) }}
                onClick={() => handleMarkerClick(station)}
                icon={{
                  url: spriteUrl,
                  size: new window.google.maps.Size(21.5, 33.5), // Full size of your sprite image (width, height)
                  origin: new window.google.maps.Point(21, 0), // Starting point of the first icon
                  scaledSize: new window.google.maps.Size(64.5, 33.5) // Displayed size of one icon
              }}
              >
              </MarkerF>
            ))}

            {selectedStation && (
              <InfoWindowF
                position={{ lat: parseFloat(selectedStation.lat), lng: parseFloat(selectedStation.lng) }}
                onCloseClick={handleInfoWindowClose}
              >
                <div>
                <div>
                  <Text as='b'><Text as='mark'>{selectedStation.name}</Text> </Text>
                  <br />
                  <Text>{selectedStation.address}, {selectedStation.locality}</Text>
                  <Text>{selectedStation.postacode}</Text>
                  <Text as='samp'>{fuelType} : </Text> <Text as='b'>{selectedStation.fuel_prices[fuelType]} €</Text>

                  </div>
                  <Button mt={3} size={'xs'} variant='solid' colorScheme='blue' onClick={() => handleGPSClick(selectedStation.lat, selectedStation.lng)}>
                    View on Maps
                  </Button>
                  </div>
              </InfoWindowF>
            )}


          </GoogleMap>

        </Box>

      </Flex>





      {allStations?.length > 0 &&
        (
          <>

            <SimpleGrid columns={[1, 1, 1, 2]} spacing='0px' mb={10}>

              {closetStation?.length > 0 &&
                (
                  <Box>
                    <Box position='relative' mt={5} mb={14} px={[5, 15, 20]}>
                      <Divider borderWidth={2} />
                      <AbsoluteCenter as='b' bg='white' px={[0, 0, 8]} fontSize={[15, 20, 22, 25]}>
                      {t('planRoute.nearestToMe')}
                      </AbsoluteCenter>
                    </Box>
                    <StationCards stations={closetStation} fuel_type={fuelType} distance={distance} />
                  </Box>
                )
              }

              {minPrice?.length > 0 &&
                (
                  <Box>
                    <Box position='relative' mb={14} mt={5} px={[5, 15, 20]}>
                      <Divider borderWidth={2} />
                      <AbsoluteCenter as='b' bg='white' px={[0, 0, 8]} fontSize={[15, 20, 22, 25]}>
                      {t('planRoute.minPriceOf')} {fuelType}
                      </AbsoluteCenter>
                    </Box>
                    <StationCards stations={minPrice} fuel_type={fuelType} distance={distance} />
                  </Box>
                )
              }


              {minDistance?.length > 0 &&
                (
                  <Box>
                    <Box position='relative' mb={14} mt={5} px={[5, 15, 20]}>
                      <Divider borderWidth={2} />
                      <AbsoluteCenter as='b' bg='white' px={[0, 0, 8]} fontSize={[15, 20, 22, 25]}>
                      {t('planRoute.minDistOfUrTrip')}
                      </AbsoluteCenter>
                    </Box>
                    <StationCards stations={minDistance} fuel_type={fuelType} distance={distance} />
                  </Box>
                )
              }

              {minCost?.length > 0 &&
                (
                  <Box>
                    <Box position='relative' mb={14} mt={5} px={[5, 15, 20]}>
                      <Divider borderWidth={2} />
                      <AbsoluteCenter as='b' bg='white' px={[0, 0, 8]} fontSize={[15, 20, 22, 25]}>
                      {t('planRoute.bestEconChoice')}
                      </AbsoluteCenter>
                    </Box>
                    <StationCards stations={minCost} fuel_type={fuelType} distance={distance} />
                  </Box>
                )
              }
            </SimpleGrid>

            <Box position='relative' mb={10} px={[10, 15, 20]}>
              <Divider borderWidth={2} />
              <AbsoluteCenter as='b' bg='white' px={[5, 8, 10]} fontSize={[20, 25, 30]}>
              {t('planRoute.allStations')}
              </AbsoluteCenter>
            </Box>
            {/* Contrôle de tri */}

            <Box m={10} pl={50} >
              <Box display='flex' alignItems='center'>
                <Text mr={5}>{t('planRoute.sortBy')}</Text>
                <Select value={selectedSort} width={300} onChange={handleSortChange}>
                  {/* <option value="default">by default</option> */}
                  <option value="default">{t('planRoute.default')}</option>
                  <option value={fuelType}>{fuelType} {t('planRoute.price')}</option>
                  <option value="distance">{t('planRoute.tripDistance')}</option>
                  {/* <option value="duration">trip duration</option> */}

                </Select>
              </Box>
            </Box>
            <StationCards stations={filteredStations} fuel_type={fuelType} distance={distance} />
          </>
        )
      }


    </>
  )
}
