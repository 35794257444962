import React from 'react'
import {
    Button,
    Flex,
    Card,
    CardBody,
    Heading,
    Text,
    SimpleGrid,
    StatNumber,
    Stat,
    StatGroup,
    StatLabel
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';



export default function StationCards({ stations, fuel_type, distance }) {
    const { t } = useTranslation(); 
    const [numCardsToShow, setNumCardsToShow] = useState(6);

    const handleShowMoreClick = () => {

        if (stations) {
            // Affichez toutes les stations restantes en un seul coup
            setNumCardsToShow(stations.length);
        }
    };

    const handleGPSClick = (lat, lng) => {
        // Construct the Google Maps URL with latitude and longitude
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

        // Open a new window with the Google Maps URL
        window.open(googleMapsUrl, '_blank');
    };

    useEffect(() => {
        // Mettez à jour numCardsToShow lorsque la propriété stations change
        if (stations) {
            setNumCardsToShow(6); // Vous pouvez définir le nbr initial de cartes à afficher ici
        }
    }, [stations]);


    return (

        <Flex justifyContent='center' flexDirection='column' >



            <SimpleGrid pr={[5, 8, 10, 20]} pl={[5, 8, 10, 20]} pb={10} minChildWidth={['200px', '300px', '450px', '600px']} placeItems='center' spacingX={15} spacingY={15}>

                {stations?.length > 0  && stations?.slice(0, numCardsToShow).map(({ id, name, address, locality, postalcode, lat, lng, fuel_prices, routeInfo }, index) => (
                    <Card key={id} variant='filled' width='100%' p={3} data-testid = 'station-card'>
                        <CardBody>
                            <Heading as='u' size={['sm', 'md']}>{name}</Heading>
                            <Text fontSize={['sm', 'md']} pt={5}> <Text as='b'>{t('stationCard.address')}:</Text> {address}, {postalcode}, {locality}</Text>

                            {fuel_type !== null && (routeInfo.status === 'OK') &&(
                                <>
                                    <Text fontSize={['sm', 'md']}><Text as='b'>{t('stationCard.tripDistance')}</Text> {(routeInfo.distance / 1000).toFixed(2)} km </Text>
                                    <Text fontSize={['sm', 'md']}><Text as='b'>{t('stationCard.detourDistance')}</Text> {Math.max(0,((routeInfo.distance - distance) / 1000).toFixed(2))} km</Text>
                                    <Text fontSize={['sm', 'md']}><Text as='b'>{t('stationCard.fromOriginToStation')}</Text> {(routeInfo.originToStationDist / 1000).toFixed(2)} km</Text>
                                    <Text fontSize={['sm', 'md']}><Text as='b'>{t('stationCard.fromStationToDestination')}</Text> {(routeInfo.stationToDestinationDist / 1000).toFixed(2)} km</Text>
                                </>
                            )}
                            {fuel_type !== null && (routeInfo.status !== 'OK') &&(
                                <>
                                    <Text fontSize={['sm', 'md']}><Text as='i'>{t('stationCard.TripDistNotComputed')}</Text></Text>
                                </>
                            )}

                            <Text fontSize={['sm', 'md']}><Text as='b'>{t('stationCard.fuelPrices')}: </Text> </Text>
                            <StatGroup  >

                                {(fuel_type === null || fuel_type === 'E10') &&
                                    <Stat>
                                        <StatLabel>{t('stationCard.super95')}</StatLabel>
                                        <StatNumber>{fuel_prices.E10 ? `${fuel_prices.E10}€` : '-'}</StatNumber>

                                    </Stat>
                                }

                                {(fuel_type === null || fuel_type === 'E5') &&
                                    <Stat>
                                        <StatLabel>{t('stationCard.super98')}</StatLabel>
                                        <StatNumber>{fuel_prices.E5 ? `${fuel_prices.E5}€` : '-'}</StatNumber>

                                    </Stat>
                                }

                                {(fuel_type === null || fuel_type === 'B7') &&
                                    <Stat>
                                        <StatLabel>{t('stationCard.diesel')}</StatLabel>
                                        <StatNumber>{fuel_prices.GO ? `${fuel_prices.GO}€` : '-'}</StatNumber>

                                    </Stat>
                                }

                            </StatGroup>
                        </CardBody>


                        <Button size={['sm', 'sm', 'md']} variant='solid' colorScheme='blue' onClick={() => handleGPSClick(lat, lng)}>
                            {t('stationCard.viewOnMaps')}
                        </Button>
                    </Card>
                    
                ))}

            </SimpleGrid>

            {stations && stations.length > 0 && numCardsToShow < stations.length && (
                <Flex justifyContent="center" mb={20}>
                    <Button data-testid = 'ShowMore' colorScheme='orange' onClick={handleShowMoreClick}>{t('stationCard.show')} {stations.length - numCardsToShow} {t('stationCard.more')}</Button>
                </Flex>
            )}

        </Flex>
    )
}
