import React, { useState } from "react";
import {
    Box, Button, Flex, IconButton, Link as ChakraLink, Menu, MenuButton, MenuItem, MenuList
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { FaGlobe } from 'react-icons/fa'; // Import the globe icon
import { BiSolidGasPump } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [display, changeDisplay] = useState("none");

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <Box>
            <Flex justifyContent='space-between' alignItems='center' mx='10%'>
                <ChakraLink as={ReactRouterLink} to='/'>
                    <img src="./LOGO.png" alt="FuelOnRoute" width={200} />
                </ChakraLink>

                <Flex align='center'>
                    <Flex display={['none', 'none', 'none', 'flex']}>
                        <Button onClick={() => navigate('/')} leftIcon={<AiFillHome />} variant='solid' p={5} mr={4}>
                            {t('Header.home')}
                        </Button>
                        <Button onClick={() => navigate('/find-nearby')} leftIcon={<FaSearch />} variant='solid' p={5} mr={4}>
                            {t('Header.findNearby')}
                        </Button>
                        <Button onClick={() => navigate('/plan-your-route')} leftIcon={<BiSolidGasPump />} variant='solid' p={5}>
                            {t('Header.planYourRoute')}
                        </Button>
                        
                        {/* Language Selection Dropdown */}
                        <Menu>
                            <MenuButton as={IconButton} icon={<FaGlobe />} variant="solid" ml={4} aria-label="Select Language">
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                                <MenuItem onClick={() => changeLanguage('de')}>Deutsch</MenuItem>
                                <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
                                <MenuItem onClick={() => changeLanguage('nl')}>Nederlands</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                    <IconButton
                        aria-label="open menu"
                        icon={<HamburgerIcon />}
                        display={['flex', 'flex', 'flex', 'none']}
                        onClick={() => changeDisplay("flex")}
                    />
                </Flex>

                <Flex
                    data-testid="menu"
                    pos='fixed'
                    top={0}
                    left={0}
                    zIndex={20}
                    display={display}
                    w='100vw'
                    h='100vh'
                    bgColor='gray.50'
                    flexDir='column'
                    overflowY='auto'
                >
                    <IconButton
                        aria-label="close menu"
                        icon={<CloseIcon />}
                        mt={2}
                        mr={2}
                        onClick={() => changeDisplay("none")}
                    />
                    <Flex flexDir='column' alignItems='center'>
                        <Button onClick={() => navigate('/')} leftIcon={<AiFillHome />} variant='solid' p={5} mr={4}>
                            {t('Header.home')}
                        </Button>
                        <Button mt={10} onClick={() => navigate('/find-nearby')} leftIcon={<FaSearch />}>
                            {t('Header.findNearby')}
                        </Button>
                        <Button mt={10} onClick={() => navigate('/plan-your-route')} leftIcon={<BiSolidGasPump />}>
                            {t('Header.planYourRoute')}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}
