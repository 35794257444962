import React from 'react'
import StationCards from '../components/StationCards/StationCards';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    Input,
    SkeletonText,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    AbsoluteCenter,
    Divider,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    Select
} from '@chakra-ui/react'
import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import { BsFillGeoAltFill } from "react-icons/bs";
import {
    useJsApiLoader,
    GoogleMap,
    Autocomplete,
    MarkerF,
    InfoWindowF
} from '@react-google-maps/api'
import { useRef, useState, useEffect } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';




const libraries = ['places']

const searchOptions = {
    componentRestrictions: { country: 'be' }, // 'be' est le code pays pour la Belgique
};
const mapcenter = { lat: 50.847761, lng: 4.351412 }



export default function FindNearby() {
    const { t } = useTranslation();  // Initialize useTranslation
    const [center, setCenter] = useState(mapcenter);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAUDSlDzK7DIOv4L54aw1PBhiiTV79wru4",
        libraries: libraries,
    })

    const [radius, setRadius] = useState(10);
    const [error, setError] = useState(null);
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [stations, setStations] = useState(null)
    const [selectedStation, setSelectedStation] = useState(null);
    const [selectedSort, setSelectedSort] = useState("default")
    const [filteredStations, setFilteredStations] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false)

    /**@type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()

    //const myIcon = 'icon_marker.png'
    const spriteUrl = 'markers.png'

    useEffect(() => {
        // Initialisation des stations filtrées avec toutes les stations
        setFilteredStations(stations);

    }, [stations]);



    // const icon = {
    //     url: 'icon_marker.png',
    //     scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
    //   };
    if (!isLoaded) {
        return <SkeletonText />
    }

    const handleChange = (value) => setRadius(value)

    const handleMarkerClick = (station) => {
        // Update the state with the selected station information
        setSelectedStation(station);
    };

    const handleInfoWindowClose = () => {
        // Clear the selected station information when closing the info window
        setSelectedStation(null);
    };

    const handleSortChange = (event) => {
        // Handle the change event for sorting options
        const selectedSortOption = event.target.value;
        setSelectedSort(selectedSortOption);

        // Assert that the selected sort option is not empty
        console.assert(selectedSortOption !== '', 'Selected sort option should not be empty');

        // Sort stations based on the selected fuel type
        if (selectedSortOption === 'E5' || selectedSortOption === 'E10' || selectedSortOption === 'B7') {
            // Use the sorting function with the selected fuel type
            const sorted = stations.slice().sort((a, b) => {
                const priceA = a.fuel_prices[selectedSortOption] === '-' ? Infinity : a.fuel_prices[selectedSortOption];
                const priceB = b.fuel_prices[selectedSortOption] === '-' ? Infinity : b.fuel_prices[selectedSortOption];
                return priceA - priceB;
            });
            setFilteredStations(sorted);
        } else if (selectedSortOption === 'default') {
            // Reset sorting to default
            setFilteredStations(stations);
        }
    };

    function clearRoute() {
        // Clear the input field, reset map center, and clear errors
        originRef.current.value = '';
        setCenter(mapcenter);
        setError(null);
        setStations(null);
        setSelectedStation("");
    }

    const handleFormSubmit = async () => {
        // Handle form submission for finding nearby stations
        setSelectedSort("default");
        const address = originRef.current.value;

        // Assert that the address is not empty
        console.assert(address.trim() !== '', 'Address should not be empty');

        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);

            console.log(latLng);

            setCenter(latLng);

            if (map) {
                map.panTo(latLng);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/find-nearby`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    origin: latLng,
                    radius: radius  // Send coordinates to the backend
                }),
            });

            if (!response.ok) {
                throw new Error('Error communicating with the server');
            }

            // Process backend response
            const data = await response.json();

            // Assert that response contains stations_data
            console.assert(data.stations_data !== undefined, 'Response should contain stations_data');

            console.log(data);

            setError(null);
            setStations(data.stations_data);
            setIsSubmitted(true);

        } catch (error) {
            setError(error);
            // Handle error, e.g., display a message to the user
        }
    };

    const handleLocaButton = () => {
        // Handle button click to get user's location
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log(latitude, longitude);
                    setCenter({ lat: latitude, lng: longitude });
                    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
                    fetch(url)
                        .then(res => res.json())
                        .then(data => {
                            const adrObj = data.address;
                            const adr = `${adrObj.road}, ${adrObj.city}`;
                            originRef.current.value = adr;
                        });
                },
                (error) => {
                    console.error('Error getting user location:', error.message);
                },
                options
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleGPSClick = (lat, lng) => {
        // Handle click event on GPS button to open Google Maps
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(googleMapsUrl, '_blank');
    };



    return (
        <>
            <Flex
                position='relative'
                flexDirection='column'
                alignItems='center'
                h='100vh'
                mb={10}
            >
                <Box
                    p={4}
                    m={4}
                    mr={4}
                    ml={4}
                    borderRadius='lg'
                    bgColor='#faf6ea '
                    width={['80%', '70%', '50%']}
                >

                    <Flex flexDirection='column' p={[3, 3, null]}>

                        <Flex direction={['column', 'column', 'column', 'row']} justifyContent={[null, null, 'space-between']}>
                            <Flex direction={'row'} mb={[5, 5, 5, 0]}>
                                <IconButton
                                    data-testid = 'Localisation'
                                    aria-label='localisation'
                                    icon={<BsFillGeoAltFill />}
                                    onClick={handleLocaButton}
                                    mr={2}
                                />

                                <Box width={[null, '100px', '200px', '300px', '500px']} flexGrow={1} bgColor='white'>
                                    <Autocomplete options={searchOptions}>
                                        <Input id='autocomplete' type='text' ref={originRef} placeholder={t('findNearby.search_stations')}/>
                                    </Autocomplete>
                                </Box>

                            </Flex>

                            <Flex justifyContent={'center'}>
                                <ButtonGroup>
                                    <Button data-testid='search' colorScheme='blue' type='submit' onClick={handleFormSubmit}>
                                    {t('findNearby.search')}
                                    </Button>
                                    <IconButton
                                        aria-label='center back'
                                        icon={<FaLocationArrow />}
                                        isRound
                                        onClick={() => map.panTo(center)}
                                    />
                                    <IconButton
                                        aria-label='clear'
                                        icon={<FaTimes />}
                                        onClick={clearRoute}
                                    />
                                </ButtonGroup>
                            </Flex>
                        </Flex>

                        <Flex direction={['column', 'column', 'column', 'row']} mt={5}>
                            <Text>
                            {t('findNearby.setRadius')}
                            </Text>

                            <Slider
                                data-testid = 'radius'
                                flex='1'
                                ml={[0, 0, 0, 10]}
                                mr={[0, 0, 0, 10]}
                                focusThumbOnChange={false}
                                value={radius}
                                onChange={handleChange}
                                min={1} max={10} step={1}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb fontSize='sm' boxSize='25px' children={radius} />
                            </Slider>
                        </Flex>
                    </Flex>



                    {error && <Alert mt={4} status='error'>
                        <AlertIcon />
                        <AlertTitle>{t('findNearby.invalidInput')}</AlertTitle>
                        <AlertDescription>{t('findNearby.invalidAddress')}</AlertDescription>
                    </Alert>
                    }


                </Box>

                {(stations === undefined || stations?.length === 0) && isSubmitted === true &&
                    (
                        <Flex m={5}>
                            <Alert status='warning'>
                                <AlertIcon />
                                {t('findNearby.noFuelStations')}
                            </Alert>
                        </Flex>
                    )
                }

                <Box position='relative' left={0} top={0} h='80%' w='80%'>
                    {/* Google Map Box */}
                    <GoogleMap
                        center={center}
                        zoom={12}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onLoad={map => setMap(map)}
                    >

                        {stations?.length > 0 && stations.map((station) => (

                            <MarkerF
                                key={station.id}
                                position={{ lat: parseFloat(station.lat), lng: parseFloat(station.lng) }}
                                onClick={() => handleMarkerClick(station)}
                                icon={{
                                    url: spriteUrl,
                                    size: new window.google.maps.Size(21.5, 33.5), // Full size of your sprite image (width, height)
                                    origin: new window.google.maps.Point(0,0), // Starting point of the first icon
                                    scaledSize: new window.google.maps.Size(64.5, 33.5) // Displayed size of one icon
                                }}
                            >

                                {/* InfoWindow to display information above the marker */}


                            </MarkerF>

                        ))}




                        {selectedStation && (
                            <InfoWindowF
                                position={{ lat: parseFloat(selectedStation.lat), lng: parseFloat(selectedStation.lng) }}
                                onCloseClick={handleInfoWindowClose}
                            >
                                <>
                                    <div>
                                        <Text as='b'><Text as='mark'>{selectedStation.name}</Text> </Text>
                                        <br />
                                        <Text>{selectedStation.address}, {selectedStation.locality}</Text>
                                        <Text>{selectedStation.postalcode}</Text>
                                        <Text as='samp'>{t('findNearby.Super95')}   :</Text> <Text as='b'> {selectedStation.fuel_prices.E10} €</Text>
                                        <br />
                                        <Text as='samp'>{t('findNearby.Super98')}   :</Text> <Text as='b'> {selectedStation.fuel_prices.E5} €</Text>
                                        <br />
                                        <Text as='samp'>{t('findNearby.DieselB7')}:</Text> <Text as='b'> {selectedStation.fuel_prices.GO} €</Text>

                                    </div>
                                    <Button mt={3} size={'xs'} variant='solid' colorScheme='blue' onClick={() => handleGPSClick(selectedStation.lat, selectedStation.lng)}>
                                    {t('findNearby.viewOnMap')}
                                    </Button>
                                </>
                            </InfoWindowF>
                        )}


                    </GoogleMap>

                </Box>




            </Flex>

            {stations?.length > 0 &&
                (
                    <>

                        <Box position='relative' mt={20} px={[10, 15, 20]}>
                            <Divider borderWidth={2} />
                            <AbsoluteCenter as='b' bg='white' px={[5, 8, 10]} fontSize={[20, 25, 30]}>
                            {t('findNearby.listOfStations')}
                            </AbsoluteCenter>
                        </Box>
                        {/* Contrôle de tri */}

                        <Box m={10} pl={[null, null, 50]} >
                            <Box display='flex' alignItems='center'>
                                <Text mr={[1, 1, 5]}>Sort by</Text>
                                <Select value={selectedSort} width={300} onChange={handleSortChange}>
                                    {/* <option value="default">by default</option> */}
                                    <option value="default">{t('findNearby.default')}</option>
                                    <option value="E10">{t('findNearby.E10price')}</option>
                                    <option value="E5">{t('findNearby.E5price')}</option>
                                    <option value="B7">{t('findNearby.B7rice')}</option>
                                </Select>
                            </Box>
                        </Box>
                        <StationCards stations={filteredStations} fuel_type={null} />
                    </>
                )
            }


        </>
    )
}
