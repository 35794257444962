import React from 'react'
import { Box, Stack, Text, HStack } from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';  // Import useTranslation

export default function ErrorPage() {
    const { t } = useTranslation();  // Initialize useTranslation

    return (
        <Box display='flex' justifyContent='center' mt={180} mb={180} mr={20} ml={20}>
            <Stack spacing={3}>
                <HStack>
                    <WarningTwoIcon data-testid="warning-icon" boxSize={70} mr={5}/>
                    <Text fontSize='60px'>{t('errorPage.title')}</Text> 
                </HStack>
                <Text fontSize='30px'>
                    {t('errorPage.description')} 
                    <a href="/">
                        <Text as='mark'>{t('errorPage.homepageLink')}</Text> 
                    </a>
                </Text>
            </Stack>
        </Box>
        
    )
}
